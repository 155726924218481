import jsApiList from './api-list'
import {Landing} from '@/network'
const wx = window.wx
export const wxInit = async upgrade => {
  const {appId} = window.initData.signature
  let configTemp = window.initData.signature
  if (upgrade) {
    const {data} = await Landing.getJsTicket({
      appId,
      queryString: location.search.slice(1)
    })
    configTemp = data
  }
  const config = Object.assign({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId, // 必填，公众号的唯一标识
    timestamp: configTemp.timestamp, // 必填，生成签名的时间戳
    nonceStr: configTemp.nonceStr, // 必填，生成签名的随机串
    signature: configTemp.signature, // 必填，签名
    jsApiList // 必填，需要使用的JS接口列表
  })
  wx.config(config)
  return wx
}
const install = async Vue => {
  if (install.installed) return
  Vue.prototype.$wx = await wxInit()
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default install
