import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 设置默认时区为 Asia/Shanghai
import moment from 'moment-timezone'
import {Charon} from '@/utils'
import {isWx, getUrlParams} from '@/utils'
import {Landing} from '@/network'

// 第三方域名解析后重定向到alpha域名
if (window.location.origin !== process.env.VUE_APP_MULTI_PAGE_URL && process.env.NODE_ENV === 'production') {
  window.location.href = `${process.env.VUE_APP_MULTI_PAGE_URL}${window.location.pathname}${window.location.search}`
}

const params = getUrlParams(window.location.href)

const appid = params.appid || process.env.VUE_APP_ID

Charon.defaults.headers.common['appid'] = appid

const initApp = () => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
}

// 获取登陆状态
const loginStatus = window.sessionStorage.getItem('loginAppId') === appid
// 重定向获取code
if (isWx() && !params.code) {
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
} else if (isWx() && params.code && !loginStatus) {
  Landing.codeToTicket({
    appid,
    code: params.code
  }).then(() => {
    // 完成登陆记录session
    window.sessionStorage.setItem('loginAppId', appid)
    return Landing.getInitData({
      appid,
      url: encodeURIComponent(window.location.href)
    })
  }).then(({data}) => {
    window.initData = data
    initApp()
    Vue.use(require('@/modules/wx').default)
  })
} else if (isWx() && params.code) {
  Landing.getInitData({
    appid,
    url: encodeURIComponent(window.location.href)
  }).then(({data}) => {
    window.initData = data
    initApp()
    Vue.use(require('@/modules/wx').default)
  })
}

moment.tz.setDefault('Asia/Shanghai')

if (!isWx()) initApp()
