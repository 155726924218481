export default [
  {
    path: '/transform/:id',
    component: {template: '<div>跳转中...</div>'},
    name: 'Transform',
    meta: {
      title: '小白理财训练营'
    }
  },
  {
    path: '/stream/success/:outTradeNo?',
    component: () => import('@/views/stream/success'),
    name: 'StreamPaySuccess',
    meta: {
      title: '支付成功'
    }
  },
  {
    path: '/stream/:id',
    component: () => import('@/views/stream/index'),
    name: 'StreamLanding',
    meta: {
      title: '小白理财训练营'
    }
  },
  {
    path: '/wechat/success/:salesmanId',
    component: () => import('@/views/wechat/success'),
    name: 'WechatPaySuccess',
    meta: {
      title: '支付成功'
    }
  },
  {
    path: '/wechat/question-list',
    component: () => import('@/views/wechat/question-list'),
    name: 'WechatQuestionList',
    meta: {
      title: '问题列表'
    }
  },
  {
    path: '/wechat/submit-phone',
    component: () => import('@/views/wechat/submit-phone'),
    name: 'WechatSubmitPhone',
    meta: {
      title: '提交手机号'
    }
  },
  {
    path: '/wechat/custom-service',
    component: () => import('@/views/wechat/custom-service'),
    name: 'WechatCustomService',
    meta: {
      title: '人工客服'
    }
  },
  {
    path: '/wechat/pay-fail',
    component: () => import('@/views/wechat/pay-fail'),
    name: 'WechatPayFail',
    meta: {
      title: '支付失败'
    }
  },
  {
    path: '/wechat/:id',
    component: () => import('@/views/wechat/index'),
    name: 'WechatLanding',
    meta: {
      title: '小白理财训练营'
    }
  }
]
