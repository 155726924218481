<template>
  <div id="app" :style="{height: heightText || '100vh' }">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      heightText: null
    }
  },
  created() {
    window.onresize = () => {
      setTimeout(() => {
        this.heightText = `${window.innerHeight}px`
      }, 100)
    }
  }
}
</script>
<style src="normalize.css"></style>
<style lang="scss">
#app {
  font-family: RegularEng, Helvetica, 'Avenir', Arial, sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  text-align: left;
  /*margin-top: 60px;*/
  background: #fff;
  height: 100vh;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
input {
  outline: none !important;
}
</style>
