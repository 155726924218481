import {Charon} from '@/utils'

Charon.defaults.baseURL = process.env.VUE_APP_BASE_API
Charon.defaults.withCredentials = true

// 落地页接口
export {default as Landing} from './landing'

Charon.interceptors.request.use((request = {}) => {
  return request
})

// 错误处理
Charon.interceptors.response.use(response => {
  return response
})
